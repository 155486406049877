import React, { useState } from "react";
import { Table, Button, Popconfirm, Tag, Select, Image } from "antd";
import {
  WholesaleOrder,
  WholesaleOrderStatus,
} from "../../types/wholesale.type";
import { API } from "../../config/config";

const { Option } = Select;

type WholesaleOrdersProps = {
  orders: WholesaleOrder[];
  userRole: (order: WholesaleOrder) => "owner" | "customer" | null;
  onStatusChange: (orderId: string, newStatus: WholesaleOrderStatus) => void;
  onCancelOrder: (orderId: string) => void;
};

const WholesaleOrders: React.FC<WholesaleOrdersProps> = ({
  orders,
  userRole,
  onStatusChange,
  onCancelOrder,
}) => {
  const [filterRole, setFilterRole] = useState<"all" | "owner" | "customer">(
    "all"
  );

  const handleStatusChange = (
    orderId: string,
    newStatus: WholesaleOrderStatus
  ) => {
    onStatusChange(orderId, newStatus);
  };

  const handleOrderCancel = async (orderId: string) => {
    await onCancelOrder(orderId);
  };

  const statusFilters = [
    { text: "Ожидание", value: WholesaleOrderStatus.Pending },
    { text: "В процессе", value: WholesaleOrderStatus.InProgress },
    { text: "Завершено", value: WholesaleOrderStatus.Completed },
    { text: "Отменено", value: WholesaleOrderStatus.Cancelled },
  ];

  const columns = [
    {
      title: "Название",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Цена",
      dataIndex: "price",
      key: "price",
      sorter: (a: WholesaleOrder, b: WholesaleOrder) => a.price - b.price,
      render: (text: number) => `${text.toFixed(2)}₽`,
    },
    {
      title: "Город",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "Владелец",
      dataIndex: "ownerName",
      key: "ownerName",
    },
    {
      title: "Телефон владельца",
      dataIndex: "ownerPhone",
      key: "ownerPhone",
    },
    {
      title: "Клиент",
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: "Телефон клиента",
      dataIndex: "customerPhone",
      key: "customerPhone",
    },
    {
      title: "Фото",
      dataIndex: "photoUri",
      key: "photoUri",
      render: (photoUri: string) => (
        <Image
          src={`${API}/${photoUri}`}
          alt="photo-preview"
          width={100}
          height={60}
          style={{ objectFit: "cover", borderRadius: "4px" }}
        />
      ),
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      filters: statusFilters,
      onFilter: (value: string | number, record: WholesaleOrder) =>
        record.status === value,
      render: (text: WholesaleOrderStatus, record: WholesaleOrder) => {
        const role = userRole(record);
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tag color={getStatusColor(text)}>{text}</Tag>
            {role === "owner" && (
              <>
                {text === WholesaleOrderStatus.Pending && (
                  <Popconfirm
                    title="Вы уверены, что хотите начать выполнение этого заказа?"
                    onConfirm={() =>
                      handleStatusChange(
                        record._id,
                        WholesaleOrderStatus.InProgress
                      )
                    }
                  >
                    <Button type="link" style={{ marginRight: "4px" }}>
                      Начать
                    </Button>
                  </Popconfirm>
                )}
                {text === WholesaleOrderStatus.InProgress && (
                  <Popconfirm
                    title="Вы уверены, что хотите завершить выполнение этого заказа?"
                    onConfirm={() =>
                      handleStatusChange(
                        record._id,
                        WholesaleOrderStatus.Completed
                      )
                    }
                  >
                    <Button type="link" style={{ marginRight: "4px" }}>
                      Завершить
                    </Button>
                  </Popconfirm>
                )}
                {(text === WholesaleOrderStatus.Pending ||
                  text === WholesaleOrderStatus.InProgress) && (
                  <Popconfirm
                    title="Вы уверены, что хотите отменить этот заказ?"
                    onConfirm={() => handleOrderCancel(record._id)}
                  >
                    <Button type="link" danger style={{ marginLeft: "4px" }}>
                      Отменить
                    </Button>
                  </Popconfirm>
                )}
              </>
            )}
            {role === "customer" && text === WholesaleOrderStatus.Pending && (
              <Popconfirm
                title="Вы уверены, что хотите отменить этот заказ?"
                onConfirm={() => handleOrderCancel(record._id)}
              >
                <Button type="link" danger>
                  Отменить
                </Button>
              </Popconfirm>
            )}
          </div>
        );
      },
    },
  ];

  const getStatusColor = (status: WholesaleOrderStatus) => {
    switch (status) {
      case WholesaleOrderStatus.Pending:
        return "orange";
      case WholesaleOrderStatus.InProgress:
        return "blue";
      case WholesaleOrderStatus.Completed:
        return "green";
      case WholesaleOrderStatus.Cancelled:
        return "red";
      default:
        return "default";
    }
  };

  const filteredOrders = orders.filter((order) => {
    const role = userRole(order);
    if (filterRole === "all") return true;
    if (filterRole === "owner" && role === "owner") return true;
    if (filterRole === "customer" && role === "customer") return true;
    return false;
  });

  return (
    <div>
      <Select
        defaultValue="all"
        style={{ marginBottom: 16, width: 200 }}
        onChange={(value) =>
          setFilterRole(value as "all" | "owner" | "customer")
        }
      >
        <Option value="all">Все заказы</Option>
        <Option value="owner">Мои заказы (Продавец)</Option>
        <Option value="customer">Мои заказы (Покупатель)</Option>
      </Select>
      <Table
        dataSource={filteredOrders}
        // @ts-ignore
        columns={columns}
        rowKey={(record) => record._id}
        pagination={{ pageSize: 10 }}
      />
    </div>
  );
};

export default WholesaleOrders;
