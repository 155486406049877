import React from "react";
import { Card, Statistic, StatisticProps } from "antd";
import CountUp from "react-countup";

interface StatCardProps {
  title: string;
  value: number;
  suffix?: string;
  precision?: number;
}

const StatCard: React.FC<StatCardProps> = ({
  title,
  value,
  suffix,
  precision,
}) => {
  const formatter: StatisticProps["formatter"] = (value) => (
    <CountUp
      end={value as number}
      duration={2}
      separator=","
      decimals={precision}
    />
  );
  return (
    <Card bordered={false} className="stat-card">
      <Statistic
        title={title}
        value={value}
        formatter={formatter}
        suffix={suffix}
      />
    </Card>
  );
};

export default StatCard;
