import { OrderStatus } from "../types/order.type";

export const getTagColor = (status: OrderStatus) => {
    const statusColors = {
      [OrderStatus.AwaitingPayment]: "orange",
      [OrderStatus.AwaitingCourier]: "blue",
      [OrderStatus.CourierPickedUp]: "geekblue",
      [OrderStatus.InTransit]: "processing",
      [OrderStatus.Delivered]: "success",
      [OrderStatus.Cancelled]: "error",
      [OrderStatus.UnderReview]: "warning",
    };
    return statusColors[status] || "default";
  };