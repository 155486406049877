import React, { useRef, useState } from "react";
import useOpenAI from "../../hooks/useOpenAI";

interface DescriptionEditorProps {
  description: string;
  setDescription: (value: string) => void;
}

const DescriptionEditor: React.FC<DescriptionEditorProps> = ({
  description,
  setDescription,
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { fetchSuggestions } = useOpenAI();
  const [suggestion, setSuggestion] = useState<string | null>(null);
  const [history, setHistory] = useState<string[]>([]);
  const [historyIndex, setHistoryIndex] = useState<number>(-1);

  const handleGetSuggestions = async (input: string) => {
    const suggestionText = await fetchSuggestions(input);
    setSuggestion(suggestionText);
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newText = e.target.value;
    setDescription(newText);

    if (historyIndex < history.length - 1) {
      setHistory(history.slice(0, historyIndex + 1));
    }
    setHistory([...history, newText]);
    setHistoryIndex(history.length);

    handleGetSuggestions(newText);
  };

  const handleUndo = () => {
    if (historyIndex > 0) {
      setHistoryIndex(historyIndex - 1);
      setDescription(history[historyIndex - 1]);
    }
  };

  const handleRedo = () => {
    if (historyIndex < history.length - 1) {
      setHistoryIndex(historyIndex + 1);
      setDescription(history[historyIndex + 1]);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Tab") {
      e.preventDefault();
      if (textareaRef.current && suggestion) {
        const cursorPosition = textareaRef.current.selectionStart;
        const newText =
          description.slice(0, cursorPosition) +
          suggestion +
          description.slice(cursorPosition);
        setDescription(newText);
        setSuggestion(null);
        textareaRef.current.focus();
      }
    } else if ((e.ctrlKey && e.key === "z") || (e.ctrlKey && e.key === "я")) {
      e.preventDefault();
      handleUndo();
    } else if ((e.ctrlKey && e.key === "y") || (e.ctrlKey && e.key === "н")) {
      e.preventDefault();
      handleRedo();
    }
  };

  return (
    <div>
      <textarea
        ref={textareaRef}
        value={description}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        rows={4}
        placeholder="Введите описание товара"
        style={{ width: "100%", fontSize: "16px", padding: "8px" }}
      />
      {suggestion && (
        <div style={{ marginTop: "10px", color: "gray" }}>{suggestion}</div>
      )}
    </div>
  );
};

export default DescriptionEditor;
