import { ProductOutlined } from "@ant-design/icons";
import { FiBriefcase, FiHome, FiShoppingBag } from "react-icons/fi";
import { GrClearOption } from "react-icons/gr";
import { FaTruckFast } from "react-icons/fa6";
import { BiSupport } from "react-icons/bi";
import { FaRegUserCircle } from "react-icons/fa";
export const items = [
  { key: "/", icon: <FiHome />, label: "Главная" },
  { key: "/products", icon: <ProductOutlined />, label: "Товары" },
  { key: "/orders", icon: <FiShoppingBag />, label: "Заказы" },
  { key: "/services", icon: <GrClearOption />, label: "Услуги" },
  { key: "/wholesale", icon: <FaTruckFast />, label: "Снабжение" },
  { key: "/vacancies", icon: <FiBriefcase />, label: "Вакансии" },
  { key: "/support", icon: <BiSupport />, label: "Поддержка" },
  { key: "/profile", icon: <FaRegUserCircle />, label: "Профиль" },
];
