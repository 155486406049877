import React from "react";
import "./App.css";
import { Auth } from "./pages/Auth/auth.page";
import "@fontsource/fira-mono";
import "@fontsource/fira-mono/400.css";
import "@fontsource/fira-mono/500.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard/dashboard.page";
import PrivateRoutes from "./utils/PrivateRoutes/PrivateRoutes";
import Root from "./components/Root/root.component";
import Products from "./pages/Products/products.page";
import Orders from "./pages/Orders/orders.page";
import Services from "./pages/Services/services.page";
import Wholesale from "./pages/Wholesale/wholesale.page";
import Profile from "./pages/Profile/profile.page";
import Vacancies from "./pages/Vacancies/vacancies.page";

function App() {
  const token = localStorage.getItem("token");

  return (
    <>
      <div className="App">
        <Routes>
          {!token && <Route path="auth" element={<Auth />} />}

          <Route element={<PrivateRoutes />}>
            <Route path="/" element={<Root />}>
              <Route index element={<Dashboard />} />
              <Route path="products" element={<Products />} />
              <Route path="orders" element={<Orders />} />
              <Route path="services" element={<Services />} />
              <Route path="wholesale" element={<Wholesale />} />
              <Route path="vacancies" element={<Vacancies />} />
              <Route path="profile" element={<Profile />} />
            </Route>
          </Route>
        </Routes>
      </div>
      <ToastContainer />
    </>
  );
}

export default App;
