import React from "react";
import { Modal, Form, Input, Button, Switch, Spin } from "antd";
import { EditWorkerValues } from "../../hooks/useWorker";

interface EditWorkerProps {
  visible: boolean;
  onClose: () => void;
  onUpdateWorker: (workerData: EditWorkerValues) => void;
  loading: boolean;
  initialValues: EditWorkerValues;
}

const EditWorker: React.FC<EditWorkerProps> = ({
  visible,
  onClose,
  onUpdateWorker,
  loading,
  initialValues,
}) => {
  const [form] = Form.useForm();

  const handleUpdate = async () => {
    try {
      const values = await form.validateFields();
      await onUpdateWorker(values);
      form.resetFields();
      onClose();
      console.log(values);
    } catch (errorInfo) {
      console.log("Validation Failed:", errorInfo);
    }
  };

  return (
    <Modal
      visible={visible}
      title="Редактировать сотрудника"
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Отмена
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleUpdate}
          loading={loading}
        >
          Сохранить
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          name="edit_worker_form"
          initialValues={initialValues}
        >
          <Form.Item
            name="name"
            label="Имя"
            rules={[{ required: true, message: "Пожалуйста, введите имя" }]}
          >
            <Input disabled={loading} />
          </Form.Item>
          <Form.Item
            name="canAddProducts"
            label="Добавление продуктов"
            valuePropName="checked"
          >
            <Switch disabled={loading} />
          </Form.Item>
          <Form.Item
            name="canDeleteProducts"
            label="Удаление продуктов"
            valuePropName="checked"
          >
            <Switch disabled={loading} />
          </Form.Item>
          <Form.Item
            name="canModifyProducts"
            label="Изменение продуктов"
            valuePropName="checked"
          >
            <Switch disabled={loading} />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default EditWorker;
