import { useState } from "react";
import {
  Button,
  Col,
  Row,
  Tabs,
  Typography,
  Input,
  Select,
  Radio,
  Spin,
  Empty,
} from "antd";
import {
  PlusCircleOutlined,
  UserOutlined,
  ExclamationCircleOutlined,
  FileDoneOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useVacancy } from "../../hooks/useVacancy";
import VacancyCard from "../../components/VacancyCard/vacancyCard.component";
import { Schedule, Vacancy } from "../../types/vacancy.type";
import NewVacancy from "../../components/NewVacancy/newVacancy.component";
import VacancyRequestsModal from "../../components/VacancyRequests/vacancyRequests.component";
import { useAuth } from "../../contexts/AuthContext/auth.context";
import { useDishonestWorker } from "../../hooks/useDishonestWorker";
import WorkerCard from "../../components/WorkerCard/workerCard.component";
import NewDishonestWorker from "../../components/NewDishonest/newDishonest.component";

const { Title } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;

export default function Vacancies() {
  const { authState } = useAuth();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedSchedule, setSelectedSchedule] = useState<Schedule | null>(
    null
  );
  const [sortOrder, setSortOrder] = useState<"ascend" | "descend" | null>(null);
  const [visible, setVisible] = useState(false);
  const [workerModalVisible, setWorkerModalVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedVacancy, setSelectedVacancy] = useState<Vacancy | null>(null);
  const [requests, setRequests] = useState<any[]>([]);
  const [requestsLoading, setRequestsLoading] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState("1");

  const {
    loading,
    vacancies,
    deleteVacancy,
    createVacancy,
    updateVacancy,
    fetchRequests,
  } = useVacancy(searchQuery, selectedSchedule, sortOrder);

  const {
    loading: dishonestLoading,
    workers,
    myWorkers,
    deleteWorker,
    createWorker,
    updateWorker,
  } = useDishonestWorker(searchQuery);

  const handleOpenApplications = async (vacancy: Vacancy) => {
    setRequestsLoading(true);
    setSelectedVacancy(vacancy);
    setModalVisible(true);
    try {
      const fetchedRequests = await fetchRequests(vacancy._id);
      setRequests(fetchedRequests);
    } catch (error) {
      console.error("Failed to load requests", error);
    } finally {
      setRequestsLoading(false);
    }
  };

  const handleNewClick = () => {
    if (activeTabKey === "1") {
      setVisible(true);
    } else if (activeTabKey === "2" || activeTabKey === "3") {
      setWorkerModalVisible(true);
    }
  };

  return (
    <div style={{ padding: "16px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 24,
        }}
      >
        <Title level={2}>Персонал</Title>
        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          onClick={handleNewClick}
        >
          Новый
        </Button>
      </div>

      <Tabs defaultActiveKey="1" onChange={(key) => setActiveTabKey(key)}>
        <TabPane
          tab={
            <span>
              <FileDoneOutlined /> Мои Вакансии
            </span>
          }
          key="1"
        >
          <div style={{ marginBottom: 24 }}>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} md={8}>
                <Input
                  placeholder="Поиск по названию"
                  prefix={<SearchOutlined />}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  allowClear
                />
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Select
                  placeholder="Фильтр по расписанию"
                  onChange={setSelectedSchedule}
                  allowClear
                  style={{ width: "100%" }}
                >
                  <Option value={Schedule.FullTime}>Полный рабочий день</Option>
                  <Option value={Schedule.PartTime}>Частичная занятость</Option>
                  <Option value={Schedule.Flexible}>Гибкий график</Option>
                </Select>
              </Col>
              <Col xs={24} sm={12} md={8}>
                <Radio.Group
                  onChange={(e) => setSortOrder(e.target.value)}
                  value={sortOrder}
                  buttonStyle="solid"
                >
                  <Radio.Button value="ascend">
                    Сортировка по зарплате ↑
                  </Radio.Button>
                  <Radio.Button value="descend">
                    Сортировка по зарплате ↓
                  </Radio.Button>
                </Radio.Group>
              </Col>
            </Row>
          </div>
          <Spin spinning={loading} tip="Загрузка...">
            <Row gutter={[16, 16]}>
              {vacancies.length > 0
                ? vacancies.map((vacancy) => (
                    <Col xs={24} sm={12} md={8} lg={6} key={vacancy._id}>
                      <VacancyCard
                        key={vacancy._id}
                        vacancy={vacancy}
                        onOpenApplications={handleOpenApplications}
                        onDeleteVacancy={deleteVacancy}
                        onUpdateVacancy={updateVacancy}
                      />
                    </Col>
                  ))
                : !loading && (
                    <Col span={24}>
                      <Empty description="Нет вакансий" />
                    </Col>
                  )}
            </Row>
          </Spin>
        </TabPane>

        {authState?.userType !== "worker" && (
          <>
            <TabPane
              tab={
                <span>
                  <UserOutlined /> Недобросовестные сотрудники
                </span>
              }
              key="2"
            >
              <div style={{ marginBottom: 24 }}>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={12} md={8}>
                    <Input
                      placeholder="Поиск по имени"
                      prefix={<SearchOutlined />}
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      allowClear
                    />
                  </Col>
                </Row>
              </div>
              <Spin spinning={dishonestLoading} tip="Загрузка...">
                <Row gutter={[16, 16]}>
                  {myWorkers.length > 0
                    ? myWorkers.map((worker) => (
                        <Col xs={24} sm={12} md={8} lg={6} key={worker._id}>
                          <WorkerCard
                            worker={worker}
                            isMy={true}
                            onDeleteWorker={deleteWorker}
                            onUpdateWorker={updateWorker}
                          />
                        </Col>
                      ))
                    : !dishonestLoading && (
                        <Col span={24}>
                          <Empty description="Нет недобросовестных сотрудников" />
                        </Col>
                      )}
                </Row>
              </Spin>
            </TabPane>

            <TabPane
              tab={
                <span>
                  <ExclamationCircleOutlined /> Все недобросовестные сотрудники
                </span>
              }
              key="3"
            >
              <div style={{ marginBottom: 24 }}>
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={12} md={8}>
                    <Input
                      placeholder="Поиск по имени"
                      prefix={<SearchOutlined />}
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      allowClear
                    />
                  </Col>
                </Row>
              </div>
              <Spin spinning={dishonestLoading} tip="Загрузка...">
                <Row gutter={[16, 16]}>
                  {workers.length > 0
                    ? workers.map((worker) => (
                        <Col xs={24} sm={12} md={8} lg={6} key={worker._id}>
                          <WorkerCard worker={worker} isMy={false} />
                        </Col>
                      ))
                    : !dishonestLoading && (
                        <Col span={24}>
                          <Empty description="Нет недобросовестных сотрудников" />
                        </Col>
                      )}
                </Row>
              </Spin>
            </TabPane>
          </>
        )}
      </Tabs>

      <NewVacancy
        visible={visible}
        onClose={() => setVisible(false)}
        onCreateVacancy={createVacancy}
      />

      <NewDishonestWorker
        visible={workerModalVisible}
        onClose={() => setWorkerModalVisible(false)}
        onCreateWorker={createWorker}
      />

      <VacancyRequestsModal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        vacancy={selectedVacancy}
        requests={requests}
        loading={requestsLoading}
      />
    </div>
  );
}
