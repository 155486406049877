export enum PriceFor {
  Kilogram = "kilogram",
  Litres = "litres",
  Pieces = "pieces",
  SquareMetre = "square metre",
  Hour = "hour",
}
export type Service = {
  _id: string;
  title: string;
  description: string;
  price: number;
  priceFor: PriceFor;
  owner: string;
  cities: string[];
  category: number;
  ownerPhoto: string;
  ownerName: string;
  createdAt: string;
};
