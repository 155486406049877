import { useState, useEffect } from "react";
import axios from "axios";
import { message } from "antd";
import { useAuth } from "../contexts/AuthContext/auth.context";
import { Product } from "../types/product.type";
import { API } from "../config/config";

export const useProduct = () => {
  const { authState } = useAuth();
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [importing, setImporting] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, SetTotalCount] = useState(0);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          `${API}/product/my?userType=${authState?.userType}&page=${currentPage}`
        );
        setProducts(response.data.products);
        SetTotalCount(response.data.totalCount);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [authState?.userType, currentPage]);

  const newProduct = async (product: FormData) => {
    try {
      const response = await axios.post(
        `${API}/product/new?userType=${authState?.userType}`,
        product
      );
      setProducts([response.data.product, ...products]);
    } catch (error: any) {
      console.error(error);
      message.error(error.response.data.error);
    }
  };

  const deleteProduct = async (productId: string) => {
    try {
      const response = await axios.delete(
        `${API}/product/delete/${productId}?userType=${authState?.userType}`
      );
      message.success(response.data.message);
      setProducts(products.filter((product) => product._id !== productId));
    } catch (error: any) {
      console.error(error);
      message.error(error.response.data.error);
    }
  };

  const updateProduct = async (productId: string, productData: FormData) => {
    try {
      const response = await axios.put(
        `${API}/product/edit/${productId}?userType=${authState?.userType}`,
        productData
      );
      setProducts(
        products.map((product) =>
          product._id === productId ? response.data.product : product
        )
      );
      message.success(response.data.message);
    } catch (error: any) {
      console.error(error);
      message.error(error.response.data.error);
    }
  };

  const importProductsFromJson = async (jsonFile: File) => {
    setImporting(true);
    const formData = new FormData();
    formData.append("file", jsonFile);

    try {
      const response = await axios.post(
        `${API}/product/import?userType=${authState?.userType}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setProducts([...response.data.products, ...products]);
      message.success("Товары успешно импортированы.");
    } catch (error: any) {
      console.error(error);
      message.error(error.response?.data?.error || "Ошибка импорта товаров.");
    } finally {
      setImporting(false);
    }
  };

  return {
    products,
    loading,
    newProduct,
    deleteProduct,
    updateProduct,
    importProductsFromJson,
    importing,
    currentPage,
    setCurrentPage,
    totalCount,
  };
};
