import {
  Typography,
  Tabs,
  Row,
  Col,
  Spin,
  Button,
  Input,
  Select,
  Empty,
} from "antd";
import {
  AppstoreAddOutlined,
  UserOutlined,
  FileDoneOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import useWholesale from "../../hooks/useWholesale";
import WholesaleCard from "../../components/WholesaleCard/wholesaleCard.component";
import { useState } from "react";
import NewWholesale from "../../components/NewWholesale/newWholesale.component";
import WholesaleOrders from "../../components/WholesaleOrders/wholesaleOrders.component";
import { useAuth } from "../../contexts/AuthContext/auth.context";
import { WholesaleOrder } from "../../types/wholesale.type";

const { Title } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;

function Wholesale() {
  const { authState } = useAuth();
  const {
    products,
    loading,
    myProducts,
    createProduct,
    buyWholesale,
    deleteWholesale,
    updateWholesale,
    orders,
    cancelOrder,
    changeOrderStatus,
    setSearchTerm,
    setSortCriteria,
    setSortOrder,
  } = useWholesale();
  
  const [isNewModalVisible, setIsNewModalVisible] = useState(false);

  const userRole = (order: WholesaleOrder) => {
    if (authState?._id === order.owner) return "owner";
    if (authState?._id === order.customer) return "customer";
    return null;
  };

  return (
    <div style={{ padding: 16 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 24,
        }}
      >
        <Title level={2}>Снабжение</Title>
        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          onClick={() => setIsNewModalVisible(true)}
        >
          Новый
        </Button>
      </div>

      {loading ? (
        <div style={{ textAlign: "center", padding: "50px 0" }}>
          <Spin size="large" />
          <p>Загрузка товаров...</p>
        </div>
      ) : (
        <Tabs defaultActiveKey="1" style={{ marginTop: 16 }}>
          <TabPane
            tab={
              <span>
                <AppstoreAddOutlined /> Все
              </span>
            }
            key="1"
          >
            <div style={{ marginBottom: 24 }}>
              <Input
                allowClear
                placeholder="Поиск"
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ marginBottom: 16, width: 300 }}
              />
              <Select
                allowClear
                placeholder="Сортировка"
                onChange={(value) => setSortCriteria(value as "title" | "price" | "date")}
                style={{ width: 200, marginRight: 16 }}
              >
                <Option value="title">Сортировка по названию</Option>
                <Option value="price">Сортировка по цене</Option>
                <Option value="date">Сортировка по дате</Option>
              </Select>
              <Select
                allowClear
                placeholder="Тип сортировки"
                onChange={(value) => setSortOrder(value as "asc" | "desc")}
                style={{ width: 200 }}
              >
                <Option value="asc">По возрастанию</Option>
                <Option value="desc">По убыванию</Option>
              </Select>
            </div>
            {products.length === 0 ? (
              <Empty description="Нет доступных товаров" />
            ) : (
              <Row gutter={[16, 16]}>
                {products.map((product) => (
                  <Col xs={24} sm={12} md={8} lg={6} key={product._id}>
                    <WholesaleCard
                      wholesale={product}
                      onBuyWholesale={buyWholesale}
                    />
                  </Col>
                ))}
              </Row>
            )}
          </TabPane>
          <TabPane
            tab={
              <span>
                <UserOutlined /> Мои Товары
              </span>
            }
            key="2"
          >
            {myProducts.length === 0 ? (
              <Empty description="У вас нет собственных товаров" />
            ) : (
              <Row gutter={[16, 16]}>
                {myProducts.map((product) => (
                  <Col xs={24} sm={12} md={8} lg={6} key={product._id}>
                    <WholesaleCard
                      wholesale={product}
                      isMyWholesale={true}
                      onDeleteWholesale={deleteWholesale}
                      onUpdateWholesale={updateWholesale}
                    />
                  </Col>
                ))}
              </Row>
            )}
          </TabPane>
          <TabPane
            tab={
              <span>
                <FileDoneOutlined /> Заказы
              </span>
            }
            key="3"
          >
            <WholesaleOrders
              orders={orders}
              userRole={(order) => userRole(order)}
              onStatusChange={(orderId, newStatus) =>
                changeOrderStatus(orderId, newStatus)
              }
              onCancelOrder={cancelOrder}
            />
          </TabPane>
        </Tabs>
      )}
      <NewWholesale
        visible={isNewModalVisible}
        onClose={() => setIsNewModalVisible(false)}
        onCreateWholesale={createProduct}
      />
    </div>
  );
}

export default Wholesale;
