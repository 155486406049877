import { useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext/auth.context";
import { DishonestWorker } from "../types/worker.type";
import axios from "axios";
import { API } from "../config/config";
import { message } from "antd";

export const useDishonestWorker = (searchQuery: string) => {
  const { authState } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [myWorkers, setMyWorkers] = useState<DishonestWorker[]>([]);
  const [workers, setWorkers] = useState<DishonestWorker[]>([]);

  const fetchMyWorkers = async () => {
    if (authState?.userType !== "seller") {
      setError("Нет доступа");
      return;
    }

    try {
      setLoading(true);
      const response = await axios.get(`${API}/worker/dishonest/my`);
      setMyWorkers(response.data.workers);
    } catch (error) {
      console.log(error);
      setError("Ошибка при получение сотрудников");
    } finally {
      setLoading(false);
    }
  };

  const fetchWorkers = async () => {
    if (authState?.userType !== "seller") {
      setError("Нет доступа");
      return;
    }

    try {
      setLoading(true);
      const response = await axios.get(`${API}/worker/dishonest`);
      setWorkers(response.data.workers);
    } catch (error) {
      console.log(error);
      setError("Ошибка при получение сотрудников");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMyWorkers();
    fetchWorkers();
  }, [authState?.userType]);

  const filteredMyWorkers = myWorkers.filter((worker) =>
    worker.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const filteredWorkers = workers.filter((worker) =>
    worker.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const deleteWorker = async (workerId: string) => {
    try {
      await axios.delete(`${API}/worker/dishonest/${workerId}/delete`);
      message.success("Недобросовестный сотрудник успешно удален.");
      setMyWorkers((prev) => prev.filter((worker) => worker._id !== workerId));
      setWorkers((prev) => prev.filter((worker) => worker._id !== workerId));
    } catch (error) {
      console.error(error);
      message.error(
        "Произошла ошибка при удалении недобросовестного сотрудника."
      );
    }
  };

  const createWorker = async (workerData: Partial<DishonestWorker>) => {
    const formattedPhone = `+7${workerData.phone}`;
    try {
      const response = await axios.post(
        `${API}/worker/dishonest/new?userType=seller`,
        {
          ...workerData,
          phone: formattedPhone,
        }
      );
      message.success("Недобросовестный сотрудник успешно создан.");
      setMyWorkers((prev) => [response.data.worker, ...prev]);
      setWorkers((prev) => [response.data.worker, ...prev]);
    } catch (error: any) {
      message.error(error.response.data.error);
    }
  };

  const updateWorker = async (
    workerId: string,
    workerData: Partial<DishonestWorker>
  ) => {
    const formattedPhone = `+7${workerData.phone}`;
    try {
      const response = await axios.put(
        `${API}/worker/dishonest/${workerId}/edit`,
        {
          ...workerData,
          phone: formattedPhone,
        }
      );
      message.success("Недобросовестный сотрудник успешно изменен.");
      setMyWorkers((prev) =>
        prev.map((worker) =>
          worker._id === workerId ? response.data.worker : worker
        )
      );
      setWorkers((prev) =>
        prev.map((worker) =>
          worker._id === workerId ? response.data.worker : worker
        )
      );
    } catch (error) {
      message.error("Произошла ошибка при изменении сотрудника.");
    }
  };

  return {
    loading,
    error,
    workers: filteredWorkers,
    myWorkers: filteredMyWorkers,
    deleteWorker,
    createWorker,
    updateWorker,
  };
};
