import { useState } from "react";
import axios from "axios";
import { OPENAI_API_KEY } from "../config/config";

const useOpenAI = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchSuggestions = async (input: string): Promise<string | null> => {
    if (input.length < 3) return null;

    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-4-turbo",
          messages: [
            { role: "system", content: "Ты копирайтер" },
            {
              role: "user",
              content: `Предложи след одно слово для текста: ${input}`,
            },
          ],
          max_tokens: 10,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${OPENAI_API_KEY}`,
          },
        }
      );

      const choices = response.data.choices;
      if (choices && choices.length > 0) {
        return choices[0].message.content.trim();
      }
      return null;
    } catch (err) {
      setError("Ошибка при получении подсказок. Попробуйте еще раз.");
      return null;
    } finally {
      setLoading(false);
    }
  };

  const generateDescription = async (title: string): Promise<string | null> => {
    if (!title) return null;

    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-4-turbo",
          messages: [
            { role: "system", content: "Ты копирайтер" },
            {
              role: "user",
              content: `Напиши описание для товара по названию: ${title}`,
            },
          ],
          max_tokens: 100,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${OPENAI_API_KEY}`,
          },
        }
      );

      const choices = response.data.choices;
      if (choices && choices.length > 0) {
        return choices[0].message.content;
      }
      return null;
    } catch (err) {
      setError("Ошибка при генерации описания. Попробуйте еще раз.");
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { fetchSuggestions, generateDescription, loading, error };
};

export default useOpenAI;
