import { PriceFor } from "./service.type";

export type WholesaleProduct = {
  _id: string;
  title: string;
  description: string;
  price: number;
  priceFor: PriceFor;
  cities: string[];
  owner: string;
  ownerName: string;
  ownerPhone: string;
  photoUri: string;
  createdAt: string;
};
export enum WholesaleOrderStatus {
  Pending = "Ожидается",
  InProgress = "В процессе",
  Completed = "Завершено",
  Cancelled = "Отменено",
}
export type WholesaleOrder = {
  _id: string;
  title: string;
  description: string;
  price: number;
  city: string;
  owner: string;
  ownerName: string;
  ownerPhone: string;
  customer: string;
  customerName: string;
  customerPhone: string;
  photoUri: string;
  status: WholesaleOrderStatus;
  createdAt: string;
};
export interface VacancyRequest {
  _id: string;
  clientId: string;
  clientName: string;
  clientPhone: string;
  vacancyId: string;
  createdAt: string;
}
