import { useState } from "react";
import { GEOCODE_API_KEY } from "../config/config";



interface UseGeocodeResult {
  geocodeLocation: (address: string) => Promise<any>;
  error: string | null;
}

export const useGeocode = (): UseGeocodeResult => {
  const [error, setError] = useState<string | null>(null);

  const geocodeLocation = async (address: string) => {
    const geocodeUrl = `https://geocode-maps.yandex.ru/1.x/?apikey=${GEOCODE_API_KEY}&geocode=${encodeURIComponent(
      address
    )}&format=json`;
    try {
      const response = await fetch(geocodeUrl);
      if (response.ok) {
        const data = await response.json();
        return data;
      }
    } catch (error) {
      setError(`Error fetching geocode data: ${error}`);
    }
  };

  return { geocodeLocation, error };
};
