import React from "react";
import { Modal, Form, Input, Button, Switch, Spin } from "antd";

interface NewWorkerProps {
  visible: boolean;
  onClose: () => void;
  onCreateWorker: (workerData: {
    name: string;
    phone: string;
    password: string;
    canAddProducts: boolean;
    canDeleteProducts: boolean;
    canModifyProducts: boolean;
  }) => void;
  loading: boolean;
}

const NewWorker: React.FC<NewWorkerProps> = ({
  visible,
  onClose,
  onCreateWorker,
  loading,
}) => {
  const [form] = Form.useForm();

  const handleCreate = async () => {
    try {
      const values = await form.validateFields();
      await onCreateWorker(values);
      form.resetFields();
      onClose();
      console.log(values);
    } catch (errorInfo) {
      console.log("Validation Failed:", errorInfo);
    }
  };

  const formatPhoneNumber = (value: string) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  };

  return (
    <Modal
      visible={visible}
      title="Создать нового сотрудника"
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Отмена
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleCreate}
          loading={loading}
        >
          Создать
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          name="new_worker_form"
          initialValues={{
            name: "",
            phone: "",
            password: "",
            canAddProducts: false,
            canDeleteProducts: false,
            canModifyProducts: false,
          }}
        >
          <Form.Item
            name="name"
            label="Имя"
            rules={[{ required: true, message: "Пожалуйста, введите имя" }]}
          >
            <Input disabled={loading} />
          </Form.Item>
          <Form.Item
            name="phone"
            label="Телефон"
            rules={[{ required: true, message: "Пожалуйста, введите телефон" }]}
          >
            <Input
              addonBefore="+7"
              placeholder="Введите ваш номер телефона"
              onChange={(e) => {
                const formattedPhoneNumber = formatPhoneNumber(e.target.value);
                form.setFieldsValue({ phone: formattedPhoneNumber });
              }}
              disabled={loading}
              style={{ marginBottom: 15 }}
            />
          </Form.Item>
          <Form.Item
            name="password"
            label="Пароль"
            rules={[{ required: true, message: "Пожалуйста, введите пароль" }]}
          >
            <Input.Password disabled={loading} />
          </Form.Item>
          <Form.Item
            name="canAddProducts"
            label="Добавление продуктов"
            valuePropName="checked"
          >
            <Switch disabled={loading} />
          </Form.Item>
          <Form.Item
            name="canDeleteProducts"
            label="Удаление продуктов"
            valuePropName="checked"
          >
            <Switch disabled={loading} />
          </Form.Item>
          <Form.Item
            name="canModifyProducts"
            label="Изменение продуктов"
            valuePropName="checked"
          >
            <Switch disabled={loading} />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default NewWorker;
