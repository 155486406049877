import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  InputNumber,
  Button,
  AutoComplete,
  Select,
  Space,
  Tag,
} from "antd";
import { PriceFor, Service } from "../../types/service.type";
import { useSuggestions } from "../../hooks/useSuggestions";
import useDebounce from "../../hooks/useDebounce";
import { services } from "../../constants/services";

interface EditServiceProps {
  visible: boolean;
  onClose: () => void;
  service: Service;
  onUpdateService?: (serviceId: string, serviceData: Partial<Service>) => void;
}

const EditService: React.FC<EditServiceProps> = ({
  visible,
  onClose,
  service,
  onUpdateService,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [cities, setCities] = useState<string[]>(service.cities);
  const [cityInput, setCityInput] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState<number | undefined>(
    service.category
  );

  const debouncedCityInput = useDebounce(cityInput, 300);
  const { options } = useSuggestions({
    query: debouncedCityInput,
    isLocality: true,
  });

  useEffect(() => {
    form.setFieldsValue({
      title: service.title,
      description: service.description,
      price: service.price,
      category: service.category,
      priceFor: service.priceFor,
    });
  }, [service, form]);

  const handleCitySelect = (value: string) => {
    if (value && !cities.includes(value)) {
      setCities([...cities, value]);
      setCityInput("");
    }
  };

  const handleCityRemove = (removedCity: string) => {
    setCities(cities.filter((city) => city !== removedCity));
  };

  const handleCategoryChange = (value: number) => {
    setSelectedCategory(value);
  };

  const handleSubmit = async (values: any) => {
    setLoading(true);

    const serviceData = {
      title: values.title,
      description: values.description,
      price: values.price,
      cities: cities,
      category: selectedCategory,
      priceFor: values.priceFor,
    };

    try {
      if (onUpdateService) {
        await onUpdateService(service._id, serviceData);
      }
      form.resetFields();
      setCities([]);
      setSelectedCategory(undefined);
      onClose();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Редактировать услугу"
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={600}
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          name="title"
          label="Название"
          rules={[
            { required: true, message: "Пожалуйста, введите название услуги!" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="description"
          label="Описание"
          rules={[
            {
              required: true,
              message: "Пожалуйста, введите описание услуги!",
            },
            {
              max: 400,
              message: "Описание не может содержать более 400 символов",
            },
            {
              min: 30,
              message: "Описание не может содержать менее 30 символов",
            },
          ]}
        >
          <Input.TextArea rows={4} minLength={30} maxLength={400} showCount />
        </Form.Item>
        <Form.Item
          name="price"
          label="Цена"
          rules={[
            {
              required: true,
              message: "Пожалуйста, введите стоимость услуги!",
            },
          ]}
        >
          <InputNumber min={0} suffix="₽" style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item label="Города">
          <AutoComplete
            options={options}
            value={cityInput}
            onSelect={handleCitySelect}
            onSearch={(value) => setCityInput(value)}
            placeholder="Введите города"
            style={{ width: "100%" }}
          >
            <Input
              value={cityInput}
              onChange={(e) => setCityInput(e.target.value)}
              placeholder="Введите города и нажмите Enter"
            />
          </AutoComplete>
          <div style={{ marginTop: 8 }}>
            <Space>
              {cities.map((city) => (
                <Tag key={city} closable onClose={() => handleCityRemove(city)}>
                  {city}
                </Tag>
              ))}
            </Space>
          </div>
        </Form.Item>
        <Form.Item
          name="category"
          label="Категория"
          rules={[
            { required: true, message: "Пожалуйста, выберите категорию!" },
          ]}
        >
          <Select
            placeholder="Выберите категорию"
            onChange={handleCategoryChange}
            value={selectedCategory}
          >
            {services.map((service) => (
              <Select.Option key={service.id} value={service.id}>
                {service.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="priceFor"
          label="Цена за"
          rules={[
            {
              required: true,
              message: "Пожалуйста, выберите, за что указывается цена!",
            },
          ]}
        >
          <Select placeholder="Выберите единицу измерения">
            {Object.values(PriceFor).map((option) => (
              <Select.Option key={option} value={option}>
                {option}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            loading={loading}
            htmlType="submit"
            style={{ marginRight: "8px" }}
          >
            Сохранить
          </Button>
          <Button onClick={onClose}>Отмена</Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditService;
