import { useEffect, useState } from "react";
import { Vacancy, Schedule } from "../types/vacancy.type";
import axios from "axios";
import { API } from "../config/config";
import { useAuth } from "../contexts/AuthContext/auth.context";
import { message } from "antd";

export const useVacancy = (
  searchQuery: string = "",
  selectedSchedule: Schedule | null = null,
  sortOrder: "ascend" | "descend" | null = null
) => {
  const { authState } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [vacancies, setVacancies] = useState<Vacancy[]>([]);

  const fetchMyVacancies = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${API}/vacancy/my?userType=${authState?.userType}`
      );
      setVacancies(response.data.vacancies);
    } catch (error) {
      console.error(error);
      setError("Failed to fetch vacancies.");
    } finally {
      setLoading(false);
    }
  };
  const fetchRequests = async (vacancyId: string) => {
    try {
      const response = await axios.get(
        `${API}/vacancy/${vacancyId}/requests?userType=${authState?.userType}`
      );
      return response.data.requests;
    } catch (error) {
      console.error("Failed to fetch requests", error);
      return [];
    }
  };
  const deleteVacancy = async (vacancyId: string) => {
    try {
      await axios.delete(
        `${API}/vacancy/delete/${vacancyId}?userType=${authState?.userType}`
      );
      message.success("Вакансия успешно удалена.");
      setVacancies((prev) =>
        prev.filter((vacancy) => vacancy._id !== vacancyId)
      );
    } catch (error) {
      console.error(error);
      message.error("Произошла ошибка при удалении вакансии.");
    }
  };
  const createVacancy = async (vacancyData: Partial<Vacancy>) => {
    try {
      const response = await axios.post(
        `${API}/vacancy/new?userType=${authState?.userType}`,
        vacancyData
      );
      message.success("Вакансия успешно создана.");
      setVacancies((prev) => [response.data.vacancy, ...prev]);
    } catch (error: any) {
      message.error(error.response.data.error);
    }
  };
  const updateVacancy = async (
    vacancyId: string,
    vacancyData: Partial<Vacancy>
  ) => {
    try {
      const response = await axios.put(
        `${API}/vacancy/edit/${vacancyId}?userType=${authState?.userType}`,
        vacancyData
      );
      message.success("Вакансия успешна изменена.");
      setVacancies((prev) =>
        prev.map((vacancy) =>
          vacancy._id === vacancyId ? response.data.vacancy : vacancy
        )
      );
    } catch (error) {
      message.error("Произошла ошибка при изменении вакансии.");
    }
  };

  useEffect(() => {
    fetchMyVacancies();
  }, [authState?.userType]);
  const filteredVacancies = (vacancies: Vacancy[]) => {
    let filtered = vacancies;

    if (searchQuery) {
      filtered = filtered.filter((vacancy) =>
        vacancy.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    if (selectedSchedule) {
      filtered = filtered.filter(
        (vacancy) => vacancy.schedule === selectedSchedule
      );
    }

    return filtered;
  };

  const sortedVacancies = (vacancies: Vacancy[]) => {
    if (sortOrder) {
      return vacancies.sort((a, b) => {
        if (sortOrder === "ascend") {
          return a.salary - b.salary;
        } else if (sortOrder === "descend") {
          return b.salary - a.salary;
        }
        return 0;
      });
    }
    return vacancies;
  };
  const filteredAndSortedVacancies = sortedVacancies(
    filteredVacancies(vacancies)
  );

  return {
    loading,
    error,
    vacancies: filteredAndSortedVacancies,
    deleteVacancy,
    createVacancy,
    updateVacancy,
    fetchRequests,
  };
};
