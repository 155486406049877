  import React, { useRef, useState } from "react";
  import {
    Typography,
    Row,
    Col,
    Spin,
    Button,
    Space,
    Input,
    Select,
    Slider,
    message,
    Empty,
    Pagination,
  } from "antd";
  import { PlusCircleOutlined } from "@ant-design/icons";
  import { categories } from "../../constants/categories.constant";
  import ProductCard from "../../components/ProductCard/productCard.component";
  import NewProductModal from "../../components/NewProduct/newProduct.component";
  import EditProductModal from "../../components/EditProduct/editProduct.component";
  import { useProduct } from "../../hooks/useProduct";
  import { Product } from "../../types/product.type";

  const { Title } = Typography;
  const { Option } = Select;

  export default function Products() {
    const {
      products,
      loading,
      newProduct,
      deleteProduct,
      updateProduct,
      importProductsFromJson,
      importing,
      currentPage,
      setCurrentPage,
      totalCount,
    } = useProduct();
    const [searchTerm, setSearchTerm] = useState("");
    const [sortCriteria, setSortCriteria] = useState("price-asc");
    const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
    const [priceRange, setPriceRange] = useState<[number, number]>([0, 100000]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);

    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const filteredProducts = products.filter((product) => {
      const matchesSearch = product.title
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      const matchesCategory = selectedCategory
        ? product.category === selectedCategory
        : true;
      const matchesPrice =
        product.options[0].price >= priceRange[0] &&
        product.options[0].price <= priceRange[1];
      return matchesSearch && matchesCategory && matchesPrice;
    });

    const sortProducts = (products: Product[], criteria: string) => {
      const sortedProducts = [...products];
      switch (criteria) {
        case "price-asc":
          sortedProducts.sort((a, b) => a.options[0].price - b.options[0].price);
          break;
        case "price-desc":
          sortedProducts.sort((a, b) => b.options[0].price - a.options[0].price);
          break;
        case "date-asc":
          sortedProducts.sort(
            (a, b) =>
              new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          );
          break;
        case "date-desc":
          sortedProducts.sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
          break;
        case "title-asc":
          sortedProducts.sort((a, b) => a.title.localeCompare(b.title));
          break;
        case "title-desc":
          sortedProducts.sort((a, b) => b.title.localeCompare(a.title));
          break;
        default:
          break;
      }
      return sortedProducts;
    };

    const sortedProducts = sortProducts(filteredProducts, sortCriteria);

    const handleEditProduct = (productId: string) => {
      const productToEdit = products.find((product) => product._id === productId);
      if (productToEdit) {
        setSelectedProduct(productToEdit);
        setIsEditModalVisible(true);
      } else {
        message.error("Не удалось найти товар для редактирования.");
      }
    };

    const handleImportJson = () => {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    };

    const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files[0]) {
        const file = e.target.files[0];
        await importProductsFromJson(file);
      }
    };
    if (loading) {
      return (
        <div style={{ textAlign: "center", padding: "50px 0" }}>
          <Spin size="large" />
          <p>Загрузка товаров...</p>
        </div>
      );
    }

    return (
      <Spin spinning={importing}>
        <div style={{ padding: "16px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 24,
            }}
          >
            <Title level={2}>Товары</Title>
            <div>
              <Button
                style={{ marginRight: 12 }}
                type="primary"
                icon={<PlusCircleOutlined />}
                onClick={() => setIsModalVisible(true)}
              >
                Новый
              </Button>

              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={onFileChange}
                accept=".json"
              />
              <Button
                type="primary"
                icon={<PlusCircleOutlined />}
                onClick={handleImportJson}
                disabled={importing}
              >
                Импортировать JSON
              </Button>
            </div>
          </div>
          <Space
            direction="horizontal"
            style={{ marginBottom: 24, flexWrap: "wrap" }}
            align="center"
          >
            <Input
              placeholder="Поиск по названию"
              value={searchTerm}
              allowClear
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ width: 240, maxWidth: "100%", marginRight: 16 }}
            />
            <Select
              placeholder="Выберите категорию"
              style={{ width: 180 }}
              onChange={(value) => setSelectedCategory(value)}
              allowClear
            >
              {categories.map((category) => (
                <Option key={category.id} value={category.id}>
                  {category.name}
                </Option>
              ))}
            </Select>
            <Slider
              range
              value={priceRange}
              min={0}
              max={100000}
              step={100}
              onChange={(value) => setPriceRange(value as [number, number])}
              style={{ width: 300, marginLeft: 16 }}
            />
            <Select
              placeholder="Cортировка"
              allowClear
              style={{ width: 180, marginLeft: 16 }}
              onChange={(value) => setSortCriteria(value)}
            >
              <Option value="price-asc">Цена: по возрастанию</Option>
              <Option value="price-desc">Цена: по убыванию</Option>
              <Option value="date-asc">Дата: по возрастанию</Option>
              <Option value="date-desc">Дата: по убыванию</Option>
              <Option value="title-asc">Название: по возрастанию</Option>
              <Option value="title-desc">Название: по убыванию</Option>
            </Select>
          </Space>
          <Row gutter={[16, 24]}>
            {sortedProducts.length > 0 ? (
              sortedProducts.map((product) => (
                <Col xs={24} sm={12} md={8} lg={6} key={product._id}>
                  <ProductCard
                    product={product}
                    deleteProduct={deleteProduct}
                    onEdit={() => handleEditProduct(product._id)}
                  />
                </Col>
              ))
            ) : (
              <Col span={24}>
                <Empty description="У вас нет товаров" />
              </Col>
            )}
          </Row>
          <Pagination
            current={currentPage}
            total={totalCount}
            pageSize={16}
            onChange={(page) => setCurrentPage(page)}
            style={{ marginTop: 16, textAlign: "center" }}
          />
          <NewProductModal
            visible={isModalVisible}
            onClose={() => setIsModalVisible(false)}
            onAddProduct={newProduct}
          />
          {selectedProduct && (
            <EditProductModal
              visible={isEditModalVisible}
              onClose={() => setIsEditModalVisible(false)}
              product={selectedProduct}
              onUpdateProduct={updateProduct}
            />
          )}
        </div>
      </Spin>
    );
  }
