import { useState } from "react";
import { Tabs, Typography, Input, Button } from "antd";
import { useAuth } from "../../contexts/AuthContext/auth.context";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const { TabPane } = Tabs;
const { Title, Text } = Typography;

const Auth = () => {
  const [showRegistration, setShowRegistration] = useState(false);
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const disabled = !phone || !password;
  const { onLogin, onSendOtp, onVerifyOtp } = useAuth();
  const navigate = useNavigate();

  const handleLogin = async (userType: "seller" | "worker") => {
    setLoading(true);
    const res = await onLogin?.(`+7${phone}`, password, userType);
    setLoading(false);
    if (res.token) {
      toast.success("Успешный вход");
      navigate("/");
    } else {
      toast.error(res.data.message || res.data.error);
    }
  };

  const handleSendOtp = async () => {
    setLoading(true);
    const res = await onSendOtp?.(`+7${phone}`, password);
    setLoading(false);
    if (res.status) {
      setOtpSent(true);
      toast.success("OTP отправлен. Пожалуйста, проверьте вашу почту.");
    } else {
      toast.error(res.data.message || res.data.error);
    }
  };

  const handleVerifyOtp = async () => {
    setLoading(true);
    const res = await onVerifyOtp?.(otp);
    setLoading(false);
    if (res.token) {
      toast.success("Успешная регистрация");
      navigate("/");
    } else {
      setOtp("")
      toast.error(res.data.message || res.data.error);
    }
  };

  const formatPhoneNumber = (value: string) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#f0f2f5",
      }}
    >
      <div
        style={{
          width: 400,
          padding: 24,
          borderRadius: 8,
          backgroundColor: "#fff",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Title level={2} style={{ marginBottom: 24, textAlign: "center" }}>
          {showRegistration ? "Регистрация" : "Вход"}
        </Title>
        {!showRegistration ? (
          <Tabs defaultActiveKey="1">
            <TabPane tab="Администратор" key="1">
              <Input
                addonBefore="+7"
                placeholder="Введите ваш номер телефона"
                value={phone}
                onChange={(e) => {
                  const formattedPhoneNumber = formatPhoneNumber(
                    e.target.value
                  );
                  setPhone(formattedPhoneNumber);
                }}
                style={{ marginBottom: 15 }}
              />
              <Input.Password
                placeholder="Введите пароль"
                style={{ marginBottom: 15 }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Text
                style={{
                  marginBottom: 15,
                  display: "block",
                  textAlign: "left",
                }}
              >
                Нет аккаунта?{" "}
                <a
                  href="#"
                  onClick={() => setShowRegistration(!showRegistration)}
                >
                  Зарегистрироваться
                </a>
              </Text>
              <Button
                disabled={disabled}
                loading={loading}
                type="primary"
                onClick={() => handleLogin("seller")}
                style={{
                  width: "40%",
                  backgroundColor: "#092325",
                  borderColor: "#092325",
                  color: "#fff",
                }}
              >
                Войти
              </Button>
            </TabPane>
            <TabPane tab="Сотрудник" key="2">
              <Input
                addonBefore="+7"
                placeholder="Введите ваш номер телефона"
                value={phone}
                onChange={(e) => {
                  const formattedPhoneNumber = formatPhoneNumber(
                    e.target.value
                  );
                  setPhone(formattedPhoneNumber);
                }}
                style={{ marginBottom: 15 }}
              />
              <Input.Password
                placeholder="Введите пароль"
                style={{ marginBottom: 15 }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button
                disabled={disabled}
                loading={loading}
                type="primary"
                onClick={() => handleLogin("worker")}
                style={{
                  width: "40%",
                  backgroundColor: "#092325",
                  borderColor: "#092325",
                  color: "#fff",
                }}
              >
                Войти
              </Button>
            </TabPane>
          </Tabs>
        ) : (
          <div>
            {!otpSent && (
              <>
                <Input
                  addonBefore="+7"
                  placeholder="Введите ваш номер телефона"
                  value={phone}
                  onChange={(e) => {
                    const formattedPhoneNumber = formatPhoneNumber(
                      e.target.value
                    );
                    setPhone(formattedPhoneNumber);
                  }}
                  style={{ marginBottom: 15 }}
                />
                <Input.Password
                  placeholder="Введите пароль"
                  style={{ marginBottom: 15 }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Text
                  style={{
                    marginBottom: 15,
                    display: "block",
                    textAlign: "left",
                  }}
                >
                  Уже есть аккаунт?{" "}
                  <a
                    href="#"
                    onClick={() => setShowRegistration(!showRegistration)}
                  >
                    Войти
                  </a>
                </Text>
                <Button
                  type="primary"
                  loading={loading}
                  disabled={!password || !phone}
                  onClick={handleSendOtp}
                  style={{
                    width: "40%",
                    backgroundColor: "#092325",
                    borderColor: "#092325",
                    color: "#fff",
                  }}
                >
                  Зарегистрироваться
                </Button>
              </>
            )}
            {otpSent && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "15px",
                  marginBottom: 15,
                }}
              >
                <Input.OTP
                  length={4}
                  size="large"
                  value={otp}
                  onChange={(text) => setOtp(text)}
                />
                <Button
                  type="primary"
                  loading={loading}
                  disabled={!otp}
                  onClick={handleVerifyOtp}
                  style={{
                    width: "40%",
                    backgroundColor: "#092325",
                    borderColor: "#092325",
                    color: "#fff",
                  }}
                >
                  Подтвердить OTP
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export { Auth };
