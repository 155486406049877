import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { Address } from "../../types/address.type";
import axios from "axios";
import { API } from "../../config/config";
import { ISchedule } from "../../types/schedule.type";

interface AuthState {
  token: string | null;
  authenticated: boolean | null;
  userType: "seller" | "worker" | null;
  phone?: string | null;
  _id?: string | null;
  name?: string | null;
  email?: string | null;
  address?: Address | null;
  photoUri?: string | null;
  banner?: string | null;
  schedule?: ISchedule | null;
  category?: string | null;
  sellerId?: string | null;
  sellerName?: string | null;
  sellerPhone?: string | null;
  canAddProducts?: boolean | null;
  canDeleteProducts?: boolean | null;
  canModifyProducts?: boolean | null;
  status?: "active" | "inactive" | null;
  isBranch?: boolean;
  sellerBranch?: string;
}
interface AuthContext {
  authState?: AuthState;
  onLogin?: (
    phone: string,
    password: string,
    userType: "seller" | "worker"
  ) => Promise<any>;
  onSendOtp?: (phone: string, password: string) => Promise<any>;
  onVerifyOtp?: (code: string) => Promise<any>;
  setAuthState: React.Dispatch<React.SetStateAction<AuthState>>;
  onSwitchBranch: (id: string) => Promise<any>;
}
const defaultAuthContext: AuthContext = {
  authState: {
    token: null,
    authenticated: null,
    userType: null,
  },
  onLogin: async () => Promise.resolve(),
  onSendOtp: async () => Promise.resolve(),
  onVerifyOtp: async () => Promise.resolve(),
  setAuthState: () => {},
  onSwitchBranch: async () => Promise.resolve(),
};
export const useAuth = () => useContext(AuthContext);

const AuthContext = createContext(defaultAuthContext);
export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [authState, setAuthState] = useState<AuthState>({
    token: null,
    authenticated: null,
    userType: null,
  });
  useEffect(() => {
    const getToken = async () => {
      try {
        const token = localStorage.getItem("token");
        console.log("TOKEN", token);
        const authStateString = localStorage.getItem("authState");

        if (token) {
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          let localState = null;

          if (authStateString) {
            localState = JSON.parse(authStateString);
            setAuthState(localState);
            console.log(authState);
          }

          if (localState && localState.userType) {
            const response = await axios.get(
              `${API}/${localState.userType}/me`
            );

            const userData = response.data;

            let specificUserData;

            if (localState.userType === "seller") {
              specificUserData = userData.seller;
            } else if (localState.userType === "worker") {
              specificUserData = userData.worker;
            }

            const updatedAuthState = {
              ...localState,
              ...specificUserData,
              authenticated: true,
            };

            setAuthState(updatedAuthState);
            localStorage.setItem("authState", JSON.stringify(updatedAuthState));
          }
        } else {
          console.log("No token found in localStorage.");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    getToken();
  }, []);

  const login = async (
    phone: string,
    password: string,
    userType: "seller" | "worker"
  ) => {
    try {
      const response = await axios.post(`${API}/${userType}/login`, {
        phone,
        password,
      });
      const { token } = response.data;
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      localStorage.setItem("token", token);

      let userData: any;

      if (userType === "seller") {
        userData = response.data.seller;
      } else if (userType === "worker") {
        userData = response.data.worker;
      }

      setAuthState((prevState) => ({
        ...prevState,
        ...userData,
        token: token,
        authenticated: true,
        userType: userType,
      }));

      localStorage.setItem(
        "authState",
        JSON.stringify({
          ...authState,
          token: token,
          authenticated: true,
          userType: userType,
          ...userData,
        })
      );
      return response.data;
    } catch (error) {
      console.error("Login error:", error);
      return (error as any).response;
    }
  };

  const sendOtp = async (phone: string, password: string) => {
    try {
      const response = await axios.post(`${API}/seller/send-otp`, {
        phone,
        password,
      });

      setAuthState({
        token: null,
        authenticated: false,
        userType: "seller",
        phone,
      });

      return response.data;
    } catch (error) {
      console.error("Registration error:", error);
      return (error as any).response;
    }
  };
  const verifyOtp = async (code: string) => {
    try {
      const response = await axios.post(`${API}/seller/verify-otp`, {
        phone: authState.phone,
        code,
      });
      const { token } = response.data;
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      localStorage.setItem("token", token);

      const userData = response.data.seller;
      setAuthState((prevstate) => ({
        ...prevstate,
        ...userData,
        token,
        authenticated: true,
        userType: "seller",
      }));
      localStorage.setItem(
        "authState",
        JSON.stringify({
          ...authState,
          ...userData,
          token: token,
          authenticated: true,
          userType: "seller",
        })
      );
      return response.data;
    } catch (error) {
      console.error("Verification:", error);
      return (error as any).response;
    }
  };
  const switchBranch = async (id: string) => {
    try {
      const response = await axios.post(`${API}/seller/switch/${id}`);
      const { seller, token } = response.data;

      setAuthState((prevState) => ({
        ...prevState,
        ...seller,
        token: token,
        authenticated: true,
      }));

      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      localStorage.setItem("token", token);

      localStorage.setItem(
        "authState",
        JSON.stringify({
          ...authState,
          ...seller,
          token: token,
          authenticated: true,
        })
      );
    } catch (error) {
      console.error("Error switching branch:", error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        onLogin: login,
        onSendOtp: sendOtp,
        onVerifyOtp: verifyOtp,
        onSwitchBranch: switchBranch,
        authState,
        setAuthState,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
