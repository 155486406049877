import React, { useState } from "react";
import { Card, Image, Typography, Spin } from "antd";
import { Product } from "../../types/product.type";
import { API } from "../../config/config";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { MdOpenInBrowser } from "react-icons/md";
import ProductModal from "../ProductModal/productModal.component";

const { Meta } = Card;
const { Paragraph } = Typography;

interface ProductCardProps {
  product: Product;
  deleteProduct: (productId: string) => void;
  onEdit?: () => void;
}

const ProductCard: React.FC<ProductCardProps> = ({
  product,
  deleteProduct,
  onEdit
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  console.log(product)
  const showModal = () => setIsModalVisible(true);
  const handleCancel = () => setIsModalVisible(false);

  const handleDeleteProduct = async () => {
    setLoading(true);
    try {
      await deleteProduct(product._id);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Card
        hoverable
        cover={
          <Image
            alt={product.title}
            src={`${API}/${product.photos[0]}`}
            style={{
              width: "100%",
              height: 180,
              objectFit: "cover",
            }}
          />
        }
        style={{
          width: 240,
          borderRadius: 8,
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          marginBottom: 16,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
        actions={[
          <MdOpenInBrowser key="open" size={20} onClick={showModal} />,
          <EditOutlined key="edit" size={20} onClick={onEdit} />, 
          loading ? (
            <Spin key="delete" size="small" />
          ) : (
            <DeleteOutlined
              key="delete"
              size={20}
              onClick={handleDeleteProduct}
            />
          ),
        ]}
      >
        <Meta
          title={product.title}
          description={`${product.options[0]?.price}₽`}
          style={{ marginBottom: 16 }}
        />
        {product.description && (
          <Paragraph
            ellipsis={{ rows: 2, expandable: false }}
            style={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              margin: 0,
            }}
          >
            {product.description}
          </Paragraph>
        )}
      </Card>
      <ProductModal
        visible={isModalVisible}
        product={product}
        onClose={handleCancel}
      />
    </>
  );
};

export default ProductCard;
