import React from "react";
import { Modal, Typography } from "antd";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { BranchStats } from "../../types/branchesStat.type";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const { Title: AntdTitle } = Typography;

interface BranchStatsModalProps {
  visible: boolean;
  onClose: () => void;
  data: BranchStats;
}

const BranchStatsModal: React.FC<BranchStatsModalProps> = ({
  visible,
  onClose,
  data,
}) => {
  const branchNames = Object.values(data).map((branch) => branch.name);
  const monthlyLabels = Object.values(data)[0]?.monthlyStats
    ? Object.keys(Object.values(data)[0].monthlyStats)
    : [];

  const revenueData = {
    labels: monthlyLabels,
    datasets: Object.keys(data).map((branchId) => ({
      label: data[branchId].name,
      data: monthlyLabels.map(
        (month) => data[branchId].monthlyStats[month]?.revenue || 0
      ),
      fill: false,
      borderColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
      tension: 0.1,
    })),
  };

  const ordersData = {
    labels: monthlyLabels,
    datasets: Object.keys(data).map((branchId) => ({
      label: data[branchId].name,
      data: monthlyLabels.map(
        (month) => data[branchId].monthlyStats[month]?.orders || 0
      ),
      fill: false,
      borderColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
      tension: 0.1,
    })),
  };

  return (
    <Modal
      title="Статистика филиалов"
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={1000}
    >
      <div style={{ marginBottom: 20 }}>
        <AntdTitle level={4}>Выручка по месяцам</AntdTitle>
        <Line
          data={revenueData}
          options={{
            responsive: true,
            plugins: { legend: { position: "top" } },
          }}
        />
      </div>
      <div>
        <AntdTitle level={4}>Заказы по месяцам</AntdTitle>
        <Line
          data={ordersData}
          options={{
            responsive: true,
            plugins: { legend: { position: "top" } },
          }}
        />
      </div>
    </Modal>
  );
};

export default BranchStatsModal;
