import React, { useState } from "react";
import { Card, Button, Avatar, Typography, Collapse, Modal } from "antd";
import { Service } from "../../types/service.type";
import { API } from "../../config/config";
import EditService from "../EditService/editService.component";
import { services } from "../../constants/services";

const { Title, Text } = Typography;
const { Panel } = Collapse;

interface ServiceCardProps {
  service: Service;
  isMyService?: boolean;
  onTakeService?: (serviceId: string) => void;
  onDeleteService?: (serviceId: string) => void;
  onUpdateService?: (serviceId: string, serviceData: Partial<Service>) => void;
}

const ServiceCard: React.FC<ServiceCardProps> = ({
  service,
  isMyService = false,
  onTakeService,
  onDeleteService,
  onUpdateService,
}) => {
  const [collapsed, setCollapsed] = useState(true);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);

  const handleUpdateService = async (
    serviceId: string,
    serviceData: Partial<Service>
  ) => {
    if (onUpdateService) {
      await onUpdateService(serviceId, serviceData);
    }
  };

  const handleTakeService = async () => {
    if (onTakeService) {
      setLoading(true);
      await onTakeService(service._id);
      setLoading(false);
      setVisible(false);
    }
  };

  const handleDeleteService = async () => {
    if (onDeleteService) {
      setDeleting(true);
      await onDeleteService(service._id);
      setDeleting(false);
    }
  };

  const getCategoryName = (categoryId: number) => {
    const category = services.find((service) => service.id === categoryId);
    return category ? category.name : "Неизвестная категория";
  };

  return (
    <>
      <Card
        style={{
          width: 300,
          borderRadius: 8,
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
        actions={[
          ...(isMyService
            ? [
                <Button
                  type="default"
                  key="edit"
                  onClick={() => setModalVisible(true)}
                >
                  Изменить
                </Button>,
                <Button
                  type="default"
                  key="delete"
                  loading={deleting}
                  onClick={() =>
                    Modal.confirm({
                      title: "Подтверждение удаления",
                      content: "Вы уверены, что хотите удалить эту услугу?",
                      okText: "Да",
                      cancelText: "Нет",
                      onOk: handleDeleteService,
                    })
                  }
                >
                  Удалить
                </Button>,
              ]
            : [
                <Button
                  type="primary"
                  key="action"
                  onClick={() => setVisible(true)}
                >
                  Получить
                </Button>,
              ]),
        ]}
      >
        <div
          style={{ display: "flex", alignItems: "center", marginBottom: 16 , justifyContent:"center" }}
        >
          <Avatar
            src={`${API}/${service.ownerPhoto}`}
            size={64}
            style={{ marginRight: 16, border: "2px solid #f0f0f0" }}
          />
        </div>
        <Title level={5} style={{ margin: "8px 0" }}>
          {service.title}
        </Title>
        <div style={{ marginBottom: 16 }}>
          <Text strong>Категория:</Text> {getCategoryName(service.category)}
        </div>
        <div style={{ marginBottom: 16 }}>
          <Text strong>Города:</Text> {service.cities.join(", ")}
        </div>
        <div style={{ marginBottom: 16 }}>
          <Text strong>Цена за:</Text> {service.priceFor}
        </div>
        <Collapse
          bordered={false}
          expandIconPosition="right"
          activeKey={collapsed ? [] : ["1"]}
          onChange={() => setCollapsed(!collapsed)}
          style={{ marginBottom: 16 }}
        >
          <Panel header="Описание" key="1">
            <Text>{service.description}</Text>
          </Panel>
        </Collapse>
        <div style={{ marginTop: 16 }}>
          <Title level={5} style={{ margin: 0 }}>
            Цена: {service.price}₽
          </Title>
        </div>
      </Card>
      <Modal
        title="Подтверждение"
        visible={visible}
        onOk={handleTakeService}
        onCancel={() => setVisible(false)}
        okText="Да"
        cancelText="Нет"
        footer={[
          <Button key="back" onClick={() => setVisible(false)}>
            Отмена
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleTakeService}
          >
            {loading ? "Получение..." : "Получить"}
          </Button>,
        ]}
      >
        <p>Вы уверены, что хотите получить эту услугу?</p>
      </Modal>
      <EditService
        visible={isModalVisible}
        onClose={() => setModalVisible(false)}
        service={service}
        onUpdateService={handleUpdateService}
      />
    </>
  );
};

export default ServiceCard;
