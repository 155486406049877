import React, { useState } from "react";
import {
  Modal,
  Typography,
  Image,
  Carousel,
  Divider,
  Select,
  Space,
  Tag,
  Row,
  Col,
} from "antd";
import { Product, IOption } from "../../types/product.type";
import { API } from "../../config/config";
import { categories } from "../../constants/categories.constant";
import { foods } from "../../constants/foods";

const { Title, Paragraph } = Typography;
const { Option } = Select;

interface ProductModalProps {
  visible: boolean;
  product: Product | null;
  onClose: () => void;
}

const ProductModal: React.FC<ProductModalProps> = ({
  visible,
  product,
  onClose,
}) => {
  const [selectedOption, setSelectedOption] = useState<IOption | null>(null);

  if (!product) return null;

  const category = categories.find((cat) => cat.id === product.category);
  const food = foods.find((food) => food.id === product.foodId);
  const handleOptionChange = (value: string) => {
    const selected = product.options.find((option) => option.choice === value);
    setSelectedOption(selected || null);
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={800}
      centered
      bodyStyle={{ padding: 0 }}
    >
      <div style={{ display: "flex" }}>
        <div style={{ width: "50%" }}>
          <Carousel autoplay arrows dotPosition="bottom" effect="scrollx">
            {product.photos.map((photo, index) => (
              <div key={index}>
                <Image
                  src={`${API}/${photo}`}
                  alt={`Product image ${index + 1}`}
                  style={{ width: "100%", height: "auto", borderRadius: "8px" }}
                />
              </div>
            ))}
          </Carousel>
        </div>

        <div style={{ flex: 1, padding: "16px" }}>
          <Title level={4} style={{ fontWeight: 600 }}>
            {product.title}
          </Title>
          <Paragraph style={{ margin: "8px 0", color: "#555" }}>
            {product.description}
          </Paragraph>
          <Row>
            <Col span={24}>
              <Title level={5} style={{ color: "#777" }}>
                Вес : {product.weight / 1000} кг.
              </Title>
              <Title level={5} style={{ color: "#777" }}>
                Категория :{" "}
                <Tag color="blue">{category?.name || "Unknown Category"}</Tag>
                {product.foodId && <Tag color="orange">{food?.name}</Tag>}
              </Title>
            </Col>
          </Row>
          <Divider style={{ margin: "16px 0" }} />

          <Space direction="vertical" style={{ width: "100%" }}>
            <Select
              value={selectedOption?.choice || product.options[0]?.choice}
              onChange={handleOptionChange}
              style={{ width: "100%", marginBottom: "16px" }}
            >
              {product.options.map((option) => (
                <Option key={option.choice} value={option.choice}>
                  {option.title} - {option.choice}
                </Option>
              ))}
            </Select>
            <Divider style={{ margin: "16px 0" }} />

            <Title level={5} style={{ fontWeight: 600 }}>
              Цена: {selectedOption?.price || product.options[0]?.price || 0}₽
            </Title>
          </Space>
        </div>
      </div>
    </Modal>
  );
};

export default ProductModal;
