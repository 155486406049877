import React, { useEffect, useState, useCallback } from "react";
import { Col, Row, Space, Typography, Table, Tag } from "antd";
import StatCard from "../../components/StatCard/statCard.component";
import useGetRequest from "../../hooks/useGetRequest";
import { API } from "../../config/config";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import {
  ChartRes,
  Recent,
  Stats,
} from "../../types/dashboard.type";
import { useAuth } from "../../contexts/AuthContext/auth.context";
import { getTagColor } from "../../utils/getTagColor";
import { OrderStatus } from "../../types/order.type";

const { Title } = Typography;

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
  Legend
);


const RecentOrdersTable: React.FC<{
  data: Recent[] | null;
  loading: boolean;
}> = ({ data, loading }) => {
  console.log(data)
  const columns = [
    {
      title: "Продукты",
      dataIndex: "products",
      key: "products",
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      render: (status: OrderStatus) => (
        <Tag color={getTagColor(status)}>{status}</Tag>
      ),
    },
    {
      title: "Дата",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Цена",
      dataIndex: "price",
      key: "price",
      render: (text: number) => `${text.toFixed(2)} ₽`,
    },
  ];

  return (
    <Table
      loading={loading}
      dataSource={data || []}
      columns={columns}
      rowKey="date"
      pagination={false}
      bordered
    />
  );
};

const RevenueChart: React.FC<{ chartSource: ChartRes[] | null }> = ({
  chartSource,
}) => {
  const chartDataForLineChart = {
    labels: chartSource?.map((item) => item.label) || [],
    datasets: [
      {
        label: "Доход",
        data: chartSource?.map((item) => item.revenue) || [],
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        fill: true,
      },
      {
        label: "Заказы",
        data: chartSource?.map((item) => item.orders) || [],
        borderColor: "rgba(153, 102, 255, 1)",
        backgroundColor: "rgba(153, 102, 255, 0.2)",
        fill: true,
      },
    ],
  };

  return <Line data={chartDataForLineChart} />;
};

const Dashboard: React.FC = () => {
  const { authState } = useAuth();
  const { data: overViewData, loading: overViewLoading } = useGetRequest(
    `${API}/seller/overview?userType=${authState?.userType}`
  );
  const { data: recentData, loading: recentLoading } = useGetRequest(
    `${API}/seller/recent?userType=${authState?.userType}`
  );
  const { data: chartData, loading: chartLoading } = useGetRequest(
    `${API}/seller/stats?userType=${authState?.userType}`
  );

  const [stats, setStats] = useState<Stats | null>(null);
  const [orders, setOrders] = useState<Recent[] | null>(null);
  const [chartSource, setChartSource] = useState<ChartRes[] | null>(null);

  const fetchData = useCallback(async () => {
    if (overViewData) setStats(overViewData);
    if (recentData) setOrders(recentData.orders);
    if (chartData) setChartSource(chartData);
  }, [overViewData, recentData, chartData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      <Title level={2}>Статистика</Title>
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={8} lg={6}>
            <StatCard
              title="Доход в этом месяце"
              value={stats?.totalEarnings || 0}
              suffix="₽"
              precision={2}
            />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <StatCard title="Кол товаров" value={stats?.productCount || 0} />
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <StatCard
              title="Заказов в этом месяце"
              value={stats?.orderCount || 0}
            />
          </Col>
        </Row>
        <Title level={2}>Недавние Заказы</Title>
        <RecentOrdersTable data={orders} loading={recentLoading} />
        <Title level={2}>График доходов и заказов</Title>
        <div style={{ height: "400px" }}>
          <RevenueChart chartSource={chartSource} />
        </div>
      </Space>
    </div>
  );
};

export default Dashboard;
