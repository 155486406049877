import { useEffect, useState } from "react";
import {
  Card,
  List,
  Avatar,
  Button,
  Typography,
  Tag,
  Empty,
  Spin,
  Modal,
  message,
} from "antd";
import { BranchesOutlined } from "@ant-design/icons";
import { useAuth } from "../../contexts/AuthContext/auth.context";
import axios from "axios";
import { API } from "../../config/config";
import useGetRequest from "../../hooks/useGetRequest";
import BranchStatsModal from "../BranchStatsModal/branchStatsModal.component";
import { BranchStats } from "../../types/branchesStat.type";

const { Text, Title } = Typography;

export default function Branches() {
  const { authState, onSwitchBranch } = useAuth();
  const [loading, setLoading] = useState(false);
  const [branches, setBranches] = useState<any[]>([]);
  const [stat, setState] = useState<BranchStats>({});
  const { data, refresh } = useGetRequest(`${API}/seller/my-branches`);
  const {
    data: statData,
    refresh: statRefresh,
    loading: statLoading,
  } = useGetRequest(`${API}/seller/branches/stats`);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    if (data) {
      setBranches(data.branches);
    }
  }, [data]);

  useEffect(() => {
    if (statData) {
      setState(statData);
    }
  }, [statData]);

  const handleSwitchBranch = async (branchId: string) => {
    setLoading(true);
    try {
      await onSwitchBranch(branchId);
      refresh();
    } catch (error) {
      console.error("Error switching branch:", error);
    } finally {
      setLoading(false);
    }
  };

  const createNewBranch = async () => {
    if (authState?.isBranch === false) {
      Modal.confirm({
        title: "Создать новые филиалы",
        content:
          "Вы уверены, что хотите создать новые филиалы? Вы можете создать несколько филиалов одновременно.",
        okText: "Да",
        cancelText: "Нет",
        onOk: async () => {
          setLoading(true);
          try {
            await axios.post(`${API}/seller/new-branch?userType=seller`, {
              count: 3,
            });
            message.success("Новые филиалы успешно созданы!");
            refresh();
          } catch (error: any) {
            message.error(
              error.response?.data?.error || "Ошибка при создании филиалов"
            );
            console.error(error.response?.data?.error || error);
          } finally {
            setLoading(false);
          }
        },
      });
    }
  };

  return (
    <>
      <Card
        bordered={false}
        style={{
          borderRadius: 12,
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          marginTop: 24,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title level={4}>Филиалы</Title>
          {authState?.isBranch === false && (
            <Button type="primary" onClick={createNewBranch}>
              Добавить
            </Button>
          )}
        </div>
        <div
          style={{
            borderBottom: "1px solid #dcdcdc",
            margin: "16px 0",
          }}
        />
        {loading ? (
          <Spin tip="Загрузка филиалов..." />
        ) : branches && branches.length > 0 ? (
          <>
            <List
              itemLayout="horizontal"
              dataSource={branches}
              renderItem={(branch) => (
                <List.Item
                  style={{
                    borderRadius: 8,
                    cursor: "pointer",
                  }}
                >
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        src={`${API}/${branch?.photoUri}`}
                        icon={<BranchesOutlined />}
                      />
                    }
                    title={
                      <Text>
                        {branch.name} {!branch.isBranch && "(главный)"}
                      </Text>
                    }
                    description={
                      <>
                        <Text>
                          {branch.address?.city}, {branch.address?.street} , дом{" "}
                          {branch.address.house} , вход{" "}
                          {branch.address.entrance} кв.
                          {branch.address.apartment}, {branch.address.floor}{" "}
                          этаж
                        </Text>
                        <br />
                        <Tag
                          color={branch.status === "active" ? "green" : "red"}
                        >
                          {branch.status}
                        </Tag>
                      </>
                    }
                  />
                  <Button
                    type="primary"
                    onClick={() => handleSwitchBranch(branch._id)}
                  >
                    Переключить
                  </Button>
                </List.Item>
              )}
            />
            {authState?.isBranch === false && (
              <Button
                type="primary"
                onClick={() => setIsModalVisible(true)}
                style={{ marginTop: 16 }}
              >
                Смотреть статистику
              </Button>
            )}
          </>
        ) : (
          <Empty description="Филиалов не найдено" />
        )}
      </Card>
      <BranchStatsModal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        data={stat}
      />
    </>
  );
}
